import styled from 'styled-components';

export const Event = ({ title, location, description, datetime, url }) => {
  return (
    <Wrapper>
      <a href={url} target="_blank" rel="noreferrer">
        <h3>{title}</h3>
      </a>
      <Location>
        {location}&nbsp;&nbsp;-&nbsp;&nbsp;{datetime}
      </Location>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  padding-right: 20px;
  &:not(first-of-type) {
    margin-top: 20px;
  }
`;

const Location = styled.h4`
  color: #7f7f7f;
`;

const Description = styled.p`
  font-size: 1rem;
  margin-top: 5px;
`;
