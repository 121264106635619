import styled from 'styled-components';

export const Subtitle = styled.div`
  font-size: calc(1rem);
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bolder;
  margin-top: 20px;
`;

export const Paragraph = styled.div`
  font-family: 'Roboto', sans-serif;
  padding-right: 20px;
  margin-top: 10px;
`;
