import styled from 'styled-components';
import { DiscordAlt, Instagram } from '@styled-icons/boxicons-logos';
import { Meetup } from '@styled-icons/simple-icons';
import Container from './Container';
import logo from '../../assets/logo.png';

export const Header = () => (
  <Background>
    <Container>
      <Wrapper>
        <Branding>
          <Logo src={logo} alt="Chicago Neighborhood Soccer Logo" />
          <Title>Chicago Neighborhood Soccer</Title>
        </Branding>
        <IconsBar />
      </Wrapper>
    </Container>
  </Background>
);

const Title = styled.h1`
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: white;
`;

const Logo = styled.img`
  margin-right: 15px;
  width: 70px;
  height: 70px;
`;

const Branding = styled.div`
  display: flex;
  align-items: center;
`;

const Background = styled.header`
  width: 100vw;
  background-color: #40b6e6;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: space-between;
`;

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 5px;
  &hover: {
    cursor: pointer;
  }
`;

const DiscordIcon = styled(DiscordAlt)`
  color: white;

  ${LinkWrapper}:hover & {
    cursor: pointer;
    color: red;
  }
`;

const IGIcon = styled(Instagram)`
  color: white;

  ${LinkWrapper}:hover & {
    cursor: pointer;
    color: red;
  }
`;

const MeetupIcon = styled(Meetup)`
  color: white;

  ${LinkWrapper}:hover & {
    cursor: pointer;
    color: red;
  }
`;

const IconsBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
`;

const IconsBar = () => {
  return (
    <IconsBarWrapper>
      <LinkWrapper
        href="https://www.meetup.com/nbhdsoccer/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MeetupIcon size={40} />
      </LinkWrapper>
      <LinkWrapper href="https://discord.gg/UApYXNmR" target="_blank">
        <DiscordIcon size={40} />
      </LinkWrapper>
      <LinkWrapper
        href="https://www.instagram.com/nbhdsoccer/?hl=en"
        target="_blank"
      >
        <IGIcon size={40} />
      </LinkWrapper>
    </IconsBarWrapper>
  );
};
