import styled from 'styled-components'
import * as UI from '../components/ui'
import { Event } from '../components'
import events from '../data/events.json'
import faqs from '../data/faq.json'

export const Home = () => {
  return (
    <div style={{ width: '100%' }}>
      <UI.Header />
      <UI.Container>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <Main>
            <UI.Callout>
              Coed soccer games for adults every Friday nights, Saturday
              mornings and every other weekday
            </UI.Callout>
            <UI.Subtitle>About Us</UI.Subtitle>
            <UI.Paragraph>
              For more than 10 years, this club has been organizing soccer
              meetups and tournaments in the West Loop and Chicago South Side.
              We, your hosts Andre, Diego, Jordan and Julian are happy that you
              found us, and we aim to provide the best outdoors soccer pick up
              experience.{' '}
            </UI.Paragraph>
            <UI.Paragraph>
              Games are competitive, but our community is very friendly to every
              player that aims to improve their skills. We match the teams as
              fairly as possible and expect to see you grow every weekend - iron
              sharpens iron.
            </UI.Paragraph>
            <UI.Paragraph>
              Although we charge for every match, our prices are very low, just
              come have a good time. Chicago has often been considered a melting
              pot city for a long time now, and it&apos;s no stretch to say it
              shows in our game. No matter which side of the city you&apos;re
              showing up from, you’re more than welcome to come kick it with us.
              Much like Chicago we have global reach too so shout out to the
              players from out of state and the worldwide soccer junkies who
              come out and play with us locals.
            </UI.Paragraph>
            <UI.Paragraph>
              Join our next events on Meetup, meet our community on Discord and
              check pictures from our last events on Instagram!{' '}
            </UI.Paragraph>
            <UI.Subtitle>Frequently Asked Questions</UI.Subtitle>
            <div>
              {faqs.map((q, i) => (
                <UI.FAQ key={i} question={q.question} answer={q.answer} />
              ))}
            </div>
          </Main>
          <SideBar>
            <UI.Subtitle>This Week</UI.Subtitle>
            <div>
              {events.map((event, i) => (
                <Event
                  key={i}
                  title={event.title}
                  location={event.location}
                  description={event.description}
                  datetime={event.datetime}
                  url={event.url}
                />
              ))}
            </div>
          </SideBar>
        </div>
      </UI.Container>
    </div>
  )
}

const SideBar = styled.section`
  width: 30%;
`

const Main = styled.section`
  width: 70%;
  padding-left: 10px;
  padding-right: 5px;
`

// const SVGBackground = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
// `;

// const HeaderSVG = () => {
//   return (
//     <SVGBackground>
//       <svg width={1036} height={400}>
//         {/* <line x1={0} y1={100} x2={1036} y2={0} stroke={"black"} /> */}
//         <path
//           d="M0,75 C600,75 570,75 600,45 C630,30 600,0 1036,0 V 300 H -1036 Z"
//           stroke="black"
//           fill="black"
//           fill-opacity="0.4"
//         />
//       </svg>
//     </SVGBackground>
//   );
// };
