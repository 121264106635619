import styled from 'styled-components';

export const FAQ = ({ question, answer }) => (
  <Wrapper>
    <Question>{question}</Question>
    <div>{answer}</div>
  </Wrapper>
);

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-top: 5px;
`;

const Question = styled.div`
  font-style: italic;
`;
